<template>
  <sdPageHeader title="Participants Detail">
  </sdPageHeader>
  <Main>
  <a-row :gutter="25">
     <a-col :xl="8" :md="8" :sm="8" :xs="24">
        <div class="participant_boxes">
          <sdCards style="text-align:center;">
              <a-avatar style="margin-bottom : 20px;" shape="circle" :size="120">
                <template #icon>
                  <sdFeatherIcons type="user" size="60" />
                </template>
              </a-avatar>
              <span v-if='subscriber.first_name != null'><h3>{{subscriber.first_name}} {{subscriber.last_name && subscriber.last_name }}</h3></span> 
              <span v-if='subscriber.address != ""'><h5>{{subscriber.address}}</h5></span> 
              <span v-if='subscriber.city != ""'><h5>{{subscriber.zip_code && subscriber.zip_code }} {{subscriber.city && subscriber.city}}</h5></span> 
              <span v-if='subscriber.phone != ""'><h5>{{subscriber.phone}}</h5></span> 
              <span v-if='subscriber.email != ""'><h5>{{subscriber.email}}</h5></span> 
              <br>
              <div :class="[getStatusColor(subscriber.is_winner), 'status']">{{participant_status[subscriber.is_winner]}}</div>
          </sdCards>
        </div>
     </a-col>
    <a-col :xl="16" :md="16" :sm="16" :xs="24">
        <a-row :gutter="25">
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.prize_name != "" && subscriber.prize_name != null'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>Prize</p>
                <h3>{{subscriber.prize_name}}</h3>
              </sdCards>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.country != ""'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>Campaign</p>
                <h3><a @click="navigateTo(`/${initial}/campaigns/details/${subscriber.campaign_encrypt_id}`)">{{ subscriber.campaign_name }}</a></h3>
              </sdCards>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.country != ""'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>Country</p>
                <h3>{{subscriber.country}}</h3>
              </sdCards>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.os != ""'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>OS</p>
                <h3>{{subscriber.os}}</h3>
              </sdCards>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.device != ""'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>Device</p>
                <h3>{{subscriber.device}}</h3>
              </sdCards>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.ip_address != ""'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>IP Address</p>
                <h3>{{subscriber.ip_address}}</h3>
              </sdCards>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.browser != ""'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>Browser</p>
                <h3>{{subscriber.browser}}</h3>
              </sdCards>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="8" :xs="24" v-if='subscriber.created_date != ""'>
            <div class="participant_boxes">
              <sdCards headless>
                <p>Date</p>
                <h3>{{getFormatedDateTime(subscriber.created_date)}}</h3>
              </sdCards>
            </div>
          </a-col>
        </a-row>
    </a-col>
  </a-row>
  </Main>
  
</template>
<script>
import moment from "moment";
import Notification from "@/services/Notification";
import { API } from "@/services/api";
import { Main } from '../../styled';
import { status } from '@/config/config.js';

export default {
  name: "SubscriberDetails",
  components: {Main},
  computed: {
    initial: function() {
      return this.$store.getters.initial;
    },
    participant_status: function() {
      return status;
    }
  },
  data() {
    return {
      status: "",
      fetching: true,
      subscriber: {},
      email: "",
    };
  },
  methods: {
      navigateTo: function (path = "") {
        if (path) {
          this.$router.replace({path});
        }
      },
      async getSubscriberDetails() {
      try {
        const { subscriber_id = "" } = this.$route.params;
        const res = await API.getSubacriberDetail({params: {
          id: subscriber_id,
        }});
        const { data, message, status } = res?.data ?? {};
        if (status) {
          this.subscriber = data;
          this.email = data.email;
        } else if(!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    
    getFormatedDateTime(date = "") {
      return moment(date).format("LLL");
    },
    getFormatedDate(date = "") {
      return moment(date).format("LL");
    },
    getStatusColor(status) {
      if(status == 1) {
        return 'Success';
      } else if(status == 0) {
        return 'error';
      } else if(status == 2) {
        return 'warning';
      } else {
        return '';
      }
    },
  },
  created() {
    this.getSubscriberDetails();
  }
}

</script>
<style>
  div.status {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
  }
</style>